.customTabs {
  .MuiBox-root {
    .MuiTabs-root {
      overflow: hidden !important;
      .MuiTabs-scroller {
        overflow: hidden !important;
        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            height: 38px !important;
            min-height: 30px !important;
            font-size: 12px;
            margin: 0px !important;
            padding: 0px 10px !important;

            &.Mui-selected {
              font-weight: 700 !important;
            }
          }
        }
        .MuiTab-root {
          position: relative;
          .MuiTabs-scroller {
            overflow-y: hidden !important;
          }
          @include border-radius(0px);
          &.Mui-selected {
            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50;
              width: 85%;
              height: 2px;
              background-color: $primary;
              border-radius: 50px;
            }
          }
          &:not(:last-child):after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 2px;
            height: 50%;
            background-color: $borderBgColor;
            border-radius: 50px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .tabs-container {
    box-shadow: 0px 10px 20px 0px rgba(231, 232, 233, 0.5);
    background-color: $whiteColor;
    border-radius: 8px;
    height: 40px;
    padding: 0px 10px;
    border: 2px solid $borderColor;
  }
}
.tabPanel {
  div[role='tabpanel'] {
    background-color: transparent !important;
  }
}
