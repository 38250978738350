.main-container {
  padding: 0px 20px;

  .go-back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    height: 28px;

    .back-arrow {
      cursor: pointer;
      :hover {
        height: 24px;
        width: 24px;
        border-radius: 30px;
        // background-color: $grayColor666;
      }
    }
  }

  .form-container {
    box-sizing: border-box;
    padding: 50px;
    margin-top: 1rem;
    background: $whiteColor;
    border: 1px solid #e7e8e9;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
      0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

.modal-design-row {
  display: flex;
  background-color: $background;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.quotation-modal-header {
  font-size: 12px !important;
  font-weight: 600 !important;
}
