@mixin border-radius($val) {
  border-radius: $val !important;
  -moz-border-radius: $val;
  -webkit-border-radius: $val;
}

//---- Font Custom ----//
@mixin font-custom($fWeight, $fSize, $lHeight) {
  font-size: $fSize !important;
  line-height: $lHeight !important;
  font-weight: $fWeight !important;
}

@mixin font-weight-bold {
  font-weight: 700 !important;
}

@mixin font-weight-semibold {
  font-weight: 600 !important;
}

@mixin font-weight-medium {
  font-weight: 500 !important;
}

@mixin font-weight-regular {
  font-weight: 400 !important;
}

@mixin font-weight($weight) {
  font-weight: $weight;
}

@mixin flex-basic-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column-center {
  @include flex-basic-center;
  flex-direction: column;
}

@mixin commonScrollBar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px $backgroundColor !important;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarBgColor !important;
    border-radius: 10px;
  }
}

//icon rotation
@mixin iconRotate($rotateVal) {
  -webkit-transform: rotate($rotateVal);
  -moz-transform: rotate($rotateVal);
  -ms-transform: rotate($rotateVal);
  -o-transform: rotate($rotateVal);
  transform: rotate($rotateVal);
}
