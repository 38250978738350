@media (min-width: 1200px) and (max-width: 1536px) {
  .userbox {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .userbox {
    display: inline-block;
  }
}

.headerwrapper {
  display: flex;
  align-items: center;
  right: 0;
  z-index: 6;
  backdrop-filter: blur(3px);
  position: fixed;
  justify-content: space-between;
  width: 100%;
}

.userSettingDropdown {
  .MuiPaper-root {
    top: 63px !important;
    width: 204px;
    left: auto !important;
    right: 0 !important;
  }
}

.top-header-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  background: $whiteColor;
  border-bottom: 1px solid #e7e8e9;
  box-shadow: $tableBoxShadow;
  height: 58px;
  left: 0px;
  top: 44px;
}

.header-top-bar {
  display: flex;
  background: #002749;
  height: 22px;
}

.icon-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 9px;

  width: 156px;
  height: 42px;
  left: 25px;
  top: 16px;
}

.volaron-text {
  width: 105px;
  height: 24px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.profile-container {
  /* Header links */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  height: 18px;
}

.logout-button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  text-align: right;

  /* Status/info-500 */
  cursor: pointer;
}
