.tableRowCellHeight {
  max-height: 48px !important;
  min-height: 48px !important;
}

.tablestyle {
  height: auto;
  width: 100%;
  .MuiCard-root {
    box-shadow: $tableBorderBoxShadow;
  }
  .MuiDataGrid-root {
    .MuiDataGrid-main {
      .MuiDataGrid-columnHeaders {
        background-color: $tableHeadersColor;
        text-transform: uppercase;
        border-color: transparent;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        @extend .tableRowCellHeight;
        .MuiDataGrid-columnHeadersInner {
          div[role='row'] {
            .MuiDataGrid-columnHeader {
              &:focus-within {
                outline: none !important;
              }
              .MuiDataGrid-columnSeparator {
                visibility: hidden;
              }
              .MuiDataGrid-columnHeaderDraggableContainer {
                .MuiDataGrid-columnHeaderTitleContainer {
                  .MuiDataGrid-columnHeaderTitleContainerContent {
                    .MuiDataGrid-columnHeaderTitle {
                      @extend .text-small;
                      @include font-weight(700);
                    }
                  }
                  .MuiDataGrid-iconButtonContainer {
                    visibility: visible !important;
                    .MuiButtonBase-root {
                      position: relative;
                      margin-left: 4px;
                      .MuiDataGrid-sortIcon {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .MuiDataGrid-columnHeaderTitle {
          @extend .text-small;
          @include font-weight(700);
        }
      }
      .MuiDataGrid-virtualScroller {
        overflow-y: scroll !important;
        &::-webkit-scrollbar {
          display: none;
        }
        @include commonScrollBar;
        .MuiDataGrid-virtualScrollerContent {
          // min-height: 65dvh !important;
          .MuiDataGrid-virtualScrollerRenderZone {
            .MuiDataGrid-row {
              position: relative;
              &:hover {
                font-weight: 100;
                &::before {
                  content: '';
                  position: absolute;
                  background-color: $secondaryColor;
                  width: 3px;
                  height: 100%;
                  bottom: 0px;
                  left: 0px;
                }
              }
              .MuiDataGrid-cell {
                @extend .text-medium;
                font-weight: 400 !important;
                // @include font-weight(300);
                border-color: $tableHeadersColor;
                .MuiSkeleton-root {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .table {
    border: 1px solid $topNavBarBorderColor;
    .MuiDataGrid-row {
      @extend .tableRowCellHeight;
      .MuiDataGrid-cell {
        @extend .tableRowCellHeight;
      }
    }
    //only for table with skeleton
    &.table-skeleton {
      .MuiDataGrid-row {
        &:hover {
          background-color: transparent;
          &::before {
            content: none !important;
          }
        }
      }
    }
  }
}

.paginationWrapper {
  border-top: 1px solid $tableHeadersColor;
  background: #000 !important;
}

.table-page-dropdown {
  font-size: 12px !important;
  .MuiSelect-outlined {
    padding-right: 7px !important;
    padding-left: 7px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

//hide table scroll for only defaultPolicyList
.defaultPolicyList {
  .tablestyle {
    .MuiDataGrid-root {
      .MuiDataGrid-main {
        .MuiDataGrid-virtualScroller {
          overflow-y: hidden !important;
        }
      }
    }
  }
}

//userListTableScrollBar specifies change
.userListTableScrollBar {
  .tablestyle {
    @media (min-width: $mediumDevice) {
      .MuiDataGrid-root {
        .MuiDataGrid-main {
          .MuiDataGrid-virtualScroller {
            &::-webkit-scrollbar {
              display: block !important;
            }
          }
        }
        .data-grid-cell-custom {
          white-space: normal !important;
          overflow: hidden !important;
          text-overflow: ellipsis;
          height: 48px !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          .text-ellipsis {
            white-space: normal !important;
          }
          &.super-app-theme--cell {
            position: sticky;
            left: 0;
            background-color: $whiteColor;
            z-index: 1;
            border-right: 1px solid $bodyContentBgColor;
          }
        }
        .super-app-theme--header {
          position: sticky;
          left: 0;
          // background-color: $whiteColor;
          z-index: 1;
          border-right: 1px solid $bodyContentBgColor;
        }
      }
    }
  }
}

//normal MUI table
.normalTable {
  table.MuiTable-root {
    min-width: 650px !important;
  }
}

.cancellation-policy-table {
  thead {
    background: #eff4f8;
    border-bottom: 1px solid #e7e8e9;
  }

  .table-header {
    &:first-child {
      border-top-left-radius: 12px !important;
    }

    &:last-child {
      border-top-right-radius: 12px !important;
    }
  }

  th {
    text-transform: capitalize;
  }
}

.MuiDialog-root .MuiPaper-root.MuiDialog-paper {
  @include commonScrollBar;
}

.price-per-night-table {
  thead {
    background: #eff4f8;
    border-bottom: 1px solid #e7e8e9;
  }

  .table-header {
    &:first-child {
      border-top-left-radius: 12px !important;
    }

    &:last-child {
      border-top-right-radius: 12px !important;
    }
  }

  tbody {
    background: white;
  }
}

.custom-table-container {
  max-width: 100%;
  overflow: auto;
  @include commonScrollBar;
  @include border-radius(10px);
  border: 1px solid $borderColor;
  background: $whiteColor;
}

.fare-details-th {
  background-color: $theadBgColor;
  color: $neutralColor;
  position: sticky;
  top: 0;
  border-right: 0px;
  text-transform: uppercase;
  text-align: start;
}

.custom-table-style {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

.css-1oktijt-MuiDataGrid-root .MuiDataGrid-row:hover,
.css-1oktijt-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background-color: #f6f6f6 !important;
}

.fare-details-container {
  @extend .custom-table-container;

  .custom-table {
    @extend .custom-table-style;

    &.round-trip {
      th {
        @extend .fare-details-th;
        font: normal 900 9px/12px $font-family-base;
        padding: 12px 20px;
      }
    }

    &.one-way {
      th {
        @extend .fare-details-th;
        font: normal 900 12px/16px $font-family-base;
        padding: 12px 20px;
      }
    }

    td {
      padding: 12px 20px;
      border-top: 1px solid $tableTdBorderColor;

      .tax-fees {
        display: flex;
        align-items: center;

        .label {
          margin-right: 2px;
        }
      }
    }
  }
}

.MuiPagination-text {
  .MuiPagination-ul {
    li:first-of-type button::after {
      content: 'Previous';
      margin-left: 8px;
    }

    li:last-of-type button::before {
      content: 'Next';
      margin-right: 8px;
    }
  }
}

.single-quote-table {
  max-height: fit-content !important;
  border: none !important;

  .MuiDataGrid-columnHeaderTitle {
    color: #333333;
    font-weight: 700 !important;
  }

  .MuiDataGrid-virtualScrollerContent {
    min-height: 220px !important;
  }
}

.sticky-header {
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 99999999;
  background-color: #e8edf0;
  border-right: 1px solid #ddd;
}

.sticky-cell {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 9999999;
  border-right: 1px solid #ddd;

  .text-ellipsis {
    width: 100% !important;
  }
}
