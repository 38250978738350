/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiInputBase-input {
  &.Mui-disabled {
    background-color: #eeeeee;
    -webkit-text-fill-color: #000 !important;
  }
}

.MuiInputBase-input {
  font-weight: 800;
}
