.accordion-panel {
  border: 1px solid $tableHeadersColor /* $table-sort-icon */;

  //   :hover {
  //     border: 1px solid $table-sort-icon;
  //   }
}

.name-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .delete-icon {
//   height: 30;
//   width: 30;
//   margin-left: 10px;
//   color: $table-sort-icon;
//   :hover {
//     color: black;
//   }
// }

.delete-icon {
  /* Base styles */
  color: $table-sort-icon;
  margin-left: 10px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-color: $whiteColor;
  border-radius: 50%;
  padding: 2px;

  /* Hover effect */
  &:hover {
    color: $blackColor;
    background-color: #ccc;
    border-radius: 50%; /* For a circular shape */
    padding: 2px;
    height: 25px;
    width: 25px;
  }
}

.dialog-action-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-icon {
  cursor: pointer;
}
