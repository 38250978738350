.fileUploadContainer {
  border: 2px dashed $uploadFileBorderColor;
  background-color: $uploadFileContainerBgColor;
  width: 100% !important;
  display: none;

  @include border-radius(8px);
  &:hover {
    border: 2px dashed $uploadFileHoverBorderColor;
    background-color: $uploadFileHoverBgColor;
  }
}
.uploadIconSize {
  font-size: 3.5rem;
}
.uploadFileErrorContainer {
  border: 2px dashed $errorTextColor;
  background-color: $uploadFileErrorBgColor;
  &:hover {
    border: 2px dashed $errorTextColor;
    background-color: $uploadFileErrorBgColor;
  }
}
.selectedFileContainer {
  // border: 1px solid $bodyContentBgColor;
  // background-color: $uploadFileContainerBgColor;
  @include border-radius(4px);
}
.progressInPercentageValue {
  position: absolute;
  bottom: 18px;
  font-weight: 700 !important;
  @extend .text-extra-small;
  // @extend .font-weight-700;
}
