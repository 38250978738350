.listDropdown {
  font: normal 400 12px/24px $font-family-base;
  position: relative;
  color: $textBlackColor;
  display: inline-flex;
  padding: 4px 8px;
  box-shadow: 0px 10px 20px 0px rgba(231, 232, 233, 0.5);
  margin-right: 12px;
  align-items: center;
  background-color: $whiteColor;
  border: 2px solid $borderColor;
  @include border-radius(8px);
  cursor: pointer;

  &:active,
  :focus {
    border: 2px solid $primaryColor;
  }

  &.open {
    border: 2px solid $primaryColor;
  }

  .width-per-182 {
    min-width: 182px;
  }

  &.disabled {
    @include border-radius(48);
    border: 1px solid $primaryButtonDisabledTextColor !important;
    background: $chipsDropDownDisabledBGColor !important;
    pointer-events: none;
    color: $primaryButtonDisabledTextColor !important;
  }
}

.listDropdown .label {
  display: block;
  top: 14px;
  // width: 0;
  height: 0;
  color: $disableColor;
  font-weight: 700 !important;
}

.listDropdown .arrow {
  display: block;
  position: absolute;
  right: 10px;
  margin: -24px 0px;
  font-weight: 700;
}

.listDropdown .selected-value .search-input {
  width: 100%;
  font-weight: 600;
  background: transparent;
  border: none !important;

  &:focus-visible {
    border: none !important;
    outline: none;
  }
}

.listDropdown .options {
  display: none;
  background-color: $whiteColor;
  box-shadow: 0px 2px 4px -2px #18274b1f;
  border: 1px solid $borderColor;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;
  text-align: start;
  right: 0;
  margin-top: 3px;
  @include commonScrollBar;
}

.listDropdown .options.open {
  display: block;
}

.listDropdown .option {
  box-sizing: border-box;
  color: $neutralColor;
  cursor: pointer;
  display: block;
  padding: 10px 20px;

  &:hover {
    background-color: $optionsBgColor;
  }

  &.selected {
    color: $primaryColor;
    font-weight: 600;
  }
}

.btn_dropdown {
  font: normal 400 14px/24px $font-family-base;
  position: relative;
  color: $textWhiteColor;
  display: inline-flex;
  padding: 7px 16px;
  box-shadow: $topNavigationDropShadows;
  align-items: center;
  background-color: $primaryColor;
  border: 2px solid $borderColor;
  @include border-radius(8px);
  cursor: pointer;

  &:active,
  :focus {
    border: 2px solid $primaryColor;
  }

  &.open {
    border: 2px solid $primaryColor;
  }

  .width-per-130 {
    min-width: 130px;
  }

  &.disabled {
    @include border-radius(48);
    border: 1px solid $primaryButtonDisabledTextColor !important;
    background: $chipsDropDownDisabledBGColor !important;
    pointer-events: none;
    color: $primaryButtonDisabledTextColor !important;
  }

  .arrow {
    display: block;
    position: absolute;
    right: 15px;
    margin: -19px 0px;
    font-weight: 700;
  }

  .selected-value .search-input {
    background: transparent;
    width: 100%;
    border: none !important;
    padding-right: 1.75rem;

    &:focus-visible {
      border: none !important;
      outline: none;
    }
  }

  .options {
    display: none;
    background-color: $whiteColor;
    border: 1px solid $borderColor;
    @include border-radius(8px);
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9999;
    -webkit-overflow-scrolling: touch;
    text-align: start;
    right: 0;
    margin-top: 4px;

    &.open {
      display: block;
    }
  }

  .option {
    box-sizing: border-box;
    color: $neutralColor;
    cursor: pointer;
    display: block;
    padding: 10px 20px;

    &:hover {
      background-color: $optionsBgColor;
    }

    &.selected {
      color: $primaryColor;
      font-weight: 600;
    }
  }

  .org-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.filter_dropdown {
  font: normal 400 14px/24px $font-family-base;
  position: relative;

  &.disabled {
    @include border-radius(48);
    border: 1px solid $primaryButtonDisabledTextColor !important;
    background: $chipsDropDownDisabledBGColor !important;
    pointer-events: none;
    color: $primaryButtonDisabledTextColor !important;
  }

  .selected-filter {
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    opacity: 0px;
    background-color: $filterSelectedBgColor;
    border: 1px solid $filterSelectedBorderColor;
  }

  .filter_options {
    padding: 10px 10px;
    display: none;
    background-color: $whiteColor;
    border: 1px solid $borderColor;
    @include border-radius(8px);
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9999;
    -webkit-overflow-scrolling: touch;
    text-align: start;
    right: 0;
    margin-top: 4px;

    &.filter_open {
      display: block;
    }
  }

  .custom-input-container {
    border: 2px solid $borderColor;
  }
}

@media (min-width: 991px) {
  .filter_dropdown_height-40 {
    height: 40px;
  }
}

.w-20per {
  width: 20%;
}

.w-25per {
  width: 25%;
}

.w-30per {
  width: 30%;
}
