.eventCommonClass {
  background-color: $white;

  &:hover {
    .bottomControls {
      display: block !important;
    }
  }

  &:active,
  &:focus::after,
  &:focus-visible {
    outline: none;
    background: none !important;
  }

  .fc-event-main {
    padding: 0;
    white-space: inherit;

    .eventBox {
      padding: 3px 0px;
      height: inherit;
      border-radius: 4px;

      &.published {
        background: rgba(229, 251, 242, 1);
        border: 2px dotted rgba(127, 213, 178, 1);

        .eventTitle {
          color: rgba(43, 148, 105, 1);
        }
      }

      &.recurring {
        background: rgba(227, 227, 227, 1);
        border: 2px dotted rgba(15, 18, 63, 0.5);

        .eventTitle {
          color: rgba(101, 103, 102, 1);
        }
      }

      &.scheduled {
        padding: 3px 6px 3px 10px;
        position: relative;
        background: rgba(255, 251, 233, 1);
        color: rgba(245, 158, 11, 1);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .eventTitle {
          font: normal 600 10px/14px $font-family-base;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .icon-arrow {
            font-size: 8px;
          }
        }

        .eventsubTitle {
          font: normal 400 10px/14px $font-family-base;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 4px;
          height: 100%;
          background-color: rgba(245, 158, 11, 1);
          border-radius: 99px 0px 0px 99px;
        }
      }

      .eventTitle {
        font: normal 600 14px/21px $font-family-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.calenderWrapper {
  height: 882px;
  width: 100%;

  //calendar header
  .calenderHeaderBar {
    padding: 0px 20px;
    border-radius: 12px 12px 0px 0px;
    background: #e9e9f8;
    min-height: 60px;

    .headerTitle {
      font: normal 500 16px/20px $font-family-base;
    }

    .nextPrevControls {
      .btnBox {
        height: 32px;
        width: 32px;
        background-color: $white;
        border: 2px solid #ccc;
        @include border-radius(50%);

        .MuiSvgIcon-root {
          color: rgba(63, 65, 89, 0.8);
        }
      }

      .monthLabel {
        font: normal 500 14px/20px $font-family-base;
        color: $black;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
      }
    }

    .headerLegends {
      display: flex;
      gap: 2.25rem;
      font: normal 400 10px/15px $font-family-base;
      ;

      .MuiBox-root {
        .legendIcon {
          height: 8px;
          width: 8px;
          border-radius: 50px;
        }

        &:nth-of-type(1) .legendIcon {
          background-color: rgba(6, 154, 220, 1);
        }

        &:nth-of-type(2) .legendIcon {
          background-color: rgba(245, 158, 11, 1);
        }
      }
    }
  }

  .fc-theme-standard {

    & td,
    & th {
      border-color: rgba(180, 181, 192, 0.5) !important;
    }
  }

  .fc {
    border-radius: 0px 0px 16px 16px;
    border: 1px solid var(--color-surface-shade-03, #e9e9f8);
    background: #fff;
    box-shadow: 0px 12.5px 10.017px 0px rgba(25, 42, 75, 0.04);

    //week view
    .fc-scrollgrid {
      border: none;

      .fc-scroller {
        @include commonScrollBar;

        .fc-col-header {
          width: 100% !important;
        }

        .fc-timegrid-body {
          width: 100% !important;

          table {
            width: 100% !important;
          }
        }

        .fc-timegrid-slot-label {
          height: 50px !important;

          .fc-timegrid-slot-label-cushion {
            //rbc-label
            color: rgba(98, 107, 121, 1);
            font: normal 400 12px/18px $font-family-base;
            letter-spacing: 0.02em;
          }
        }

      }

      .selectedCol.fc-day {
        background-color: rgba(232, 232, 232, 1);
      }

      .fc-day-today {
        background-color: initial;
      }

      .fc-timegrid-col-frame {

        .fc-v-event {
          border: none;
        }

        .fc-event {
          margin-top: 1px;
          z-index: 1;
          @extend .eventCommonClass;
        }
      }
    }
  }
}

.customWeekHeader {
  padding: 5px;
  // margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;

    .MuiFormControlLabel-label {
      font: normal 5600 15px/20px $font-family-base;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: $calendarTextColor;
    }
  }

  .headerDayLabel {
    font: normal 500 13px/20px $font-family-base;
    letter-spacing: 0.02em;
    color: $calendarTextColor;
  }

  .headerDayLabelVal {
    font: normal 600 16px/20px $font-family-base;
    letter-spacing: 0.02em;
    color: $calendarTextColor;

    &.weekHeaderClassCurrentDate {
      background-color: $primary;
      color: $white;
      border-radius: 25px;
      height: 26px;
      width: 26px;
      @extend .flex-basic-center;
    }
  }
}