//all varibles goes under this files
$font-family-base: 'Poppins';
$font-family-icomoon: 'icomoon';
//main colors,

$primary: #2d5bff;
// $secondaryColor: #e64949;
$tertiary: #161e54;
$textColor: #1a2128;
$primaryColor: #ef7f1a;
$secondaryColor: #0d5bf2;
$white: #fff;
$black: 000000;
$borderColor: #ccc;
$borderBgColor: #eeeeee;
$backgroundColor: #f1f4f5;
$scrollBarBgColor: rgba(105, 129, 155, 0.2);
$grayColor: #8c9093;
$darkGrayColor: #5f6162;
$grayColor200: #333;
$grayColor666: #666666;
$textColor: #304050;
$grayComponentColor: #979797;
$neutralColor: #333;
$paginationActiveButtonBgColor: #e7effe;

$borderColor: #ccc;
$borderBgColor: #eeeeee;
$backgroundColor: #edf2f3;
$scrollBarBgColor: rgba(105, 129, 155, 0.5);
$adminLoginbackgroundColor: #525fbd;

//progress stats color
$progressError: #e55957;
$progressSuccess: #009f46;
$progressWarning: #f6841b;
$progressStrong: #ffc30b;

//progress status bg color with opacity
$progressErrorBg: rgba(229, 89, 87, 0.585);
$progressSuccessBg: rgba(0, 159, 69, 0.486);
$progressWarningBg: rgba(246, 133, 27, 0.545);
$progressStrongBg: rgba(255, 194, 11, 0.495);
$errorTextColor: #ff1943;

//table
$table-bg-color: #fff;
$table-header: #818b9a;
$table-checkbox-checked: #4e2788;
$table-checkbox-unchecked: #e9e9f8;
$table-hover: rgba(233, 233, 248, 0.4);
$pagination-selected-bd: #31125f;
$pagination-font: #ffffff;
$table-sort-icon: #aeaeae;
$table-cell-text: #2e384d;
$tableHeadersColor: #e8edf0;
$tableBorderBoxShadow: 0px 4px 6px -4px #18274b1f;
$tableHeaderSortIconColor: rgba(34, 51, 84, 0.039);

//modal
$delete-modal-subtitle: rgb(234, 84, 85);
$colorNeutralGrey03: #e9e9f8;

/*******Card*******/
$tableBoxShadow: 0px 0px 6px #9f9f9f43;
$sidebarBgShadowColor: 0px 9px 16px rgba(159, 162, 191, 0.18),
  0px 2px 2px rgba(159, 162, 191, 0.32);
$tabBoxShadow: 0px 1px 2px 0px #32324714;

/*******BreakPoint*******/
$mediumDevice: 960px;
$mediumDeviceDown: 959px;
$smallDeviceDown: 600px;
$extraSmallDeviceUp: 599px;

//status Chips colors
$statustextColor: #464f60;
$statusChipsWarningBgColor: #fff6e9;
$statusChipsErrorBgColor: #ffefe7;
$statusChipsSuccessBgColor: #d3f1e5;
$statusChipsInfoBgColor: #e8f0fb;
$statusChipsCloseIconColor: #b4aeae;

$statusChipsWarningBorderColor: #ffc369;
$statusChipsErrorBorderColor: #ff51514d;
$statusChipsSuccessBorderColor: #7fd5b2;
$statusChipsInfoBorderColor: #3786f14d;
$switchDisabledBorderColor: #dadada;

//input fields
$inputFieldBorderColor: #2452eb24;

//divider bg color
$dividerBorderColor: #bdbdbd;

//messages inside the empty state widget
$textMessageColor: #6a6a6a;
$stateBackgroundColor: #f3f6ff;

//candidateListItems
$candidateListItemsPrimaryColor: #0f123f;

//calendar
$calendarTextDisabled: #b4b5c0;
$calenderDisableBgColor: rgba(180, 181, 192, 0.1);
$bg-surface-color: #4e2788;
$calenderWeekHeaderBackground: rgba(78, 39, 136, 1);
$calendarTextColor: rgba(18, 20, 51, 0.9);

$user-default-profile: #c0cae9;

//pending/Accepted color
$pendingColor: #d18f2b;
$acceptedColor: #1b8f5f;
$resumeNameColor: #565656;

//user active - inactive color
$activeStatusColor: #0db63c;
$inactiveStatusColor: #e23c3c;
$cardNumberBoxColor: #f4f6fc;
$dashboardTextColor: #0f123f;

//jd
$jd-titleColor: #6c6c6c;

$whiteColor: #ffffff;

// ----------------------------------------TJ Variables-----------------------------------------------

//all varibles goes under this files
//please alwyas add new variables at bottom of file

$fontSize: 1rem; //16px base font size

/******* Colors *******/
$primaryColor: #2d5bff;
// $secondaryColor: #e64949;
$tertiaryColor: #004684;
$neutralColor: #333;
$greyColor: rgba(209, 211, 212, 1);
$topHeaderMenuColor: #464646;
$tertiary-100: #b0c6d9;
$primary-100: rgba(255, 242, 235, 1);
$neutralColor: #333;
$borderPrimaryTraveller: #b4ccfb;
$ghostBtnColor: #464646;
$stepTitleColor: #333333;
$borderHoverColor: #7a96fb;
$tertiary-500: rgba(0, 70, 132, 1);
$activeFilterBg: #fff2eb;

// Tertiary Button css
$tertiaryButtonDisabledIconColor: #9fa3a9;
$tertiaryButtonDisabledTextColor: #9fa3a9;

// Secondary Button
$secondaryButtonDisabledBorderColor: #c8c8c8;

/******* Text and Icon Colors *******/
$blackColor: #000000;
$textColor: rgba(51, 51, 51, 1);
$iconColor: #747473;
$whiteColor: #ffffff;
$borderColor: #e7e8e9;
$textColor1: #9fa3a9;
$textColor2: #9fa3a9;
$textColor3: #9fa3a9;
$textColor4: #9fa3a9;
$borderBgColor: #eeeeee;
$backgroundColor: #edf2f3;
$scrollBarBgColor: rgba(177, 187, 198, 0.5);
$scrollBarBgColorTab: rgba(144, 156, 170, 0.5);
$bodyContentBgColor: #dfe4e8;
$backDropBgColor: rgba(0, 0, 0, 0.6);
$avatarBorderColor: #ccc;
$blackTextColor: #223354;
$iconGenericColor: #767d83;
$overlayChipBorderColor: #e7e8e9;
$disableColor: #717171;
$background: #eff4f8;
$bgbackgroundcolor: #c8c8c8;
$textNotYetSharedColor: #7234ff;
$background: #eff4f8;
$bgbackgroundcolor: #c8c8c8;
$iconLocationColor: #373737;

/******* status colors *******/
$statusInfoColor: #0d5bf2;
$statusWarningColor: #f2890d;
$statusDangerColor: #e5193b;
$statusInfoBgColor: #e7effe;
$statusWarningBgColor: #fef3e7;
$statusDangerBgColor: #fce8eb;
$statusDisableColor: #e7e8e999;
$statusSuccessColor: #159a43;
$statusBookedColor: #8c10d7;
$errorTextColor: #ff1943;
$statusBookingColor: rgba(6, 168, 168, 1);
$statusBookingCancelledColor: rgba(0, 70, 132, 1);
$statusInfoBorderColor: #b4ccfb;

//status bg color
$statusInfoBackGroundColor: #e7effe;
$statusWarningBackGroundColor: #fef3e7;
$statusDangerBackGroundColor: #fce8eb;
$statusInfoBackGroundColor: #e7effe;
$statusWarningBackGroundColor: #fef3e7;
$statusSuccessBackGroundColor: #e7f3eb;
$statusBookedBackGroundColor: #f2eaf7;
$statusClosedBackGroundColor: #e6edf3;
$chipsDropDownDisabledBGColor: rgba(231, 232, 233, 0.6);
$primaryButtonDisabledTextColor: #9fa3a9;
$statusBookingBackGroundColor: rgba(223, 244, 244, 1);
$statusBookingCancelledBgColor: rgba(230, 237, 243, 1);
$bgNotYetSharedColor: #f1ebff;
$hrLineColor: #f1f1f12e;
$ghostBtnBgColor: #eff4f8;
$ghostBtnBorderColor: #767d83;
$placeholderColor: #e7e8e94d;

/******* table component colors *******/
$tableHeadersColor: #e8edf0;
$tableBorderBoxShadow: 0px 4px 6px -4px #18274b1f;
$tableHeaderSortIconColor: rgba(34, 51, 84, 0.039);

/******* Top Nav bar *******/
$topNavBarShadow: 0px 10px 20px 0px #e7e8e966;
$topNavBarBorderColor: #e7e8e9;
$topNavigationDropShadows: 0px 10px 20px 0px rgba(231, 232, 233, 0.4);

/******* Switch Colors *******/
$switchDisabledBorderColor: #dadada;
$switchBorderColor: #9a9a9a;
$switchThumbBgColor: #e0e0e0;

/******* Upload File *******/
$uploadFileContainerBgColor: rgba(239, 244, 248, 0.7);
$uploadFileBorderColor: #cfd7db;
$uploadFileErrorBgColor: #d32f2f0a;
$uploadFileHoverBgColor: #e5eef5;
$uploadFileHoverBorderColor: #5483ad;

/******* Upload File *******/
// $paginationActiveButtonBgColor: #fff2eb;

/******* Stepper *******/
$subtitleColor: #888888;
$stepperIconBordercolor: #aa5a12;

/*******Login Page*******/
$searchBackIamgeUrl: url('../assets/images/flightSearchBackImage.png');
$loginPageBackgroundImageUrl: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ),
  url('../assets/images/login-background-img.jpeg');

$landingBackgroundImageUrl: url('../assets/images/landing-new-bg.png');
$landingMobileBackgroundImageUrl: url('../assets/images/bg-for-mobile.png');

$airCraftBackgroundImageUrl: url('../assets/images/aircraft-head.png');
$aircraftHeadBackgroundImageUrl: url('../assets/images/aircraft-head.png');

$airCraftBackgroundImageUrl: url('../assets/images/aircraft-head.png');
$aircraftHeadBackgroundImageUrl: url('../assets/images/aircraft-head.png');

$otpInputBorderColor: #0000003b;
$toastBorderColor: #c8c8c8;

/*******Popover*******/
$organizationActionButtonPopoverBorderColor: #d2d4d6;

/*******Calendar*******/
$calenderBoxBorderColor: #cfd8dc;
$calenderBoxShadaw: 0px 2px 4px -2px #18274b1f;

/*******Calendar*******/
$profilePictureBoxBgColor: #e7e8e9;
$profilePictureTextColor: #555;
$updateIconBorderColor: #c8c8c8;
$breadcrumbsIconColor: #717171;

/*******Card*******/
$sidebarBgShadowColor: 0px 9px 16px rgba(159, 162, 191, 0.18),
  0px 2px 2px rgba(159, 162, 191, 0.32);
$tabBoxShadow: 0px 1px 2px 0px #32324714;

/*******BreakPoint*******/
$mediumDevice: 960px;
$mediumDeviceDown: 959px;
$smallDeviceDown: 600px;
$extraSmallDeviceUp: 599px;

/*******dashboard*******/
$bookingCardImageBoxBgColor: rgba(239, 244, 248, 1);
$bookingCardBorderColor: rgb(176, 198, 217);
$extraLargeDevice: 1920px;
$cardLayoutBoxBoxColor: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
$addPassengerDropDownBoxShadow: 0px 10px 20px 0px #e7e8e966;
$incrementDecrementCountBgColor: #fff2eb;
$overlayChipBackgroundColor: #eff4f8;

/*******DatePicker*******/
$inputBorder: rgba(200, 200, 200, 1);
$errorTextColor: #f45244;
$textColor1: #9fa3a9;
$textColor2: #9fa3a9;
$textColor3: #9fa3a9;
$textColor4: #9fa3a9;
$qrCodeShadowColor: rgba(231, 232, 233, 0.4);
$primary-100: rgba(255, 242, 235, 1);
$textColor: rgba(51, 51, 51, 1);
$labelsFontColor: #717171;

/*******Status DropDown*******/
$textBlackColor: #1a1c18;
$textWhiteColor: rgba(255, 255, 255, 1);
$optionsBgColor: #ebebff;
$filterSelectedBgColor: rgba(255, 242, 235, 1);
$filterSelectedBorderColor: rgba(231, 232, 233, 1);
$logoUploadBg: #eff4f8;
$switchBoxBorderColor: rgba(176, 198, 217, 1);

/*******fare rule table Box *******/
$theadBgColor: #e9eef2;
$tableTdBorderColor: #e0e2e4;
$tableBoxShadowColor: 0px 8px 8px -4px rgba(24, 39, 75, 0.08),
  0px 4px 6px -4px rgba(24, 39, 75, 0.12);

/*********Rejection Container************/
$rejectionContainerBgColor: #fce8eb;
$rejectionContainerBorderColor: #e5193b;
$rejectionReasonTextColor: #7e0e20;

//dropBox
$selectBoxDropBgColor: rgba(252, 235, 220, 0);
/*********Sorting Container************/
$sortingContainerBackground: #e2e8ed;

//switch button
$switchButtonDisabledColor: #e0e0e0;
$switchButtonEnabledColor: #004684;

// Slider Styles
$sliderRailColor: #eceff1;

/*********Report Colors************/
$legendBorder: #e0e0e0;
$legendBgHeader: #eff4f8;

/*************Slider Styles**************/
// Hotel Image container
$hotelImgContainer: rgba(222, 228, 231, 1);

// Fare box bg color
$fareBgColor: rgba(242, 244, 244, 1);

// Verification label
$bestRatePrimary: rgba(114, 64, 218, 1);
$bestRateSecondary: rgba(243, 237, 255, 1);
$bestRateBorder: rgba(198, 180, 251, 1);
$tjVerifiedPrimary: rgba(21, 154, 67, 1);
$tjVerifiedSecondary: rgba(231, 243, 235, 1);
$tjVerifiedBorder: rgba(180, 217, 193, 1);
$legendBgHeader: #eff4f8;
