.status-container {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  border: 1px solid;
  width: fit-content;

  &.success {
    background-color: #e6f7e6;
    color: #389e0d;
    border-color: #389e0d;

    .status-dot {
      background-color: #389e0d;
    }
  }

  &.warning {
    background-color: #fff4e5;
    color: #d48806;
    border-color: #d48806;

    .status-dot {
      background-color: #d48806;
    }
  }

  &.danger {
    background-color: #fdecea;
    color: #cf1322;
    border-color: #cf1322;

    .status-dot {
      background-color: #cf1322;
    }
  }

  &.pending {
    background-color: #e6f7ff;
    color: #1890ff;
    border-color: #1890ff;

    .status-dot {
      background-color: #1890ff;
    }
  }

  .status-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .status-text {
    font-size: 12px;
  }
}
