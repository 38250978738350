@import '../variables';
@import '../mixins';

.large {
  display: inline-block;
}
.small {
  display: none;
}
.SidebarWrapper {
  position: relative;
  z-index: 7;
  height: 100%;
  padding-bottom: 68px;
}

//top Header
.sideBar {
  background-color: $whiteColor;
  box-shadow: $tableBoxShadow;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  white-space: nowrap;
  .icon-sso {
    font-size: 24px !important;
  }
  @include border-radius(10px);
  @media (max-width: $mediumDeviceDown) {
    .sidebar-menu-item {
      display: flex !important;
      justify-content: space-between;

      .sidebar-menu {
        padding: 20px 32px !important;
        &.active {
          &::after {
            width: 100% !important;
            height: 3px !important;
          }
        }
      }
    }
  }
  @media (min-width: $mediumDevice) {
    width: 90px;
    &:hover {
      // position: absolute;
      // width: 240px;
      // z-index: 99999;
      .itemName {
        display: block !important;
      }
      .iconWithItem {
        justify-content: center !important;
      }
    }
  }

  .sidebar-menu-item {
    list-style-type: none;
    display: inline-grid;
    padding: 0;
    overflow: hidden;
    width: 100%;
    margin-bottom: 0px;
    .sidebar-menu {
      @extend .p-relative;
      float: left;
      display: block;
      color: $neutralColor;
      text-align: center;
      padding: 20px;
      text-decoration: none;
      cursor: pointer;

      .itemName {
        display: none;
        @extend .text-medium;
      }
      [class^='icon-'] {
        font-size: 20px;
      }
      &:hover {
        background-color: $paginationActiveButtonBgColor;
      }
      &.active {
        background-color: $paginationActiveButtonBgColor;
        color: $secondaryColor;
        .itemName {
          // @include font-weight(700);
          font-weight: 700;
        }
        &::after {
          content: '';
          position: absolute;
          opacity: 0.6;
          background-color: $secondaryColor;
          width: 3px;
          height: 100%;
          bottom: 0px;
          right: 0px;
        }
      }
    }
  }
}
